import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_TOKEN)) || null,
    twfValid: JSON.parse(sessionStorage.getItem(process.env.VUE_APP_TWF_TOKEN)) || false,
    version: process.env.VUE_APP_VERSION || "0.0.0",
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "black",
    setHorizontalLayout: false, // Horizontal layout
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_TWF(state, payload) {
      state.twfValid = payload
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
  },
  actions: {
    LOGOUT({ commit }) {
      router.go('/')
      localStorage.removeItem(process.env.VUE_APP_USER_TOKEN)
      sessionStorage.removeItem(process.env.VUE_APP_TWF_TOKEN);
      delete axios.defaults.headers.common['Authorization']
      commit('SET_USER', {})
    },
    SET_USER({ commit, dispatch }, payload) {
      if (!payload) dispatch('LOGOUT')
      axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`
      commit('SET_USER', payload)
    },
    SET_TWF({ commit, dispatch }, payload) {
      if (!payload ) dispatch('LOGOUT')
      sessionStorage.setItem(process.env.VUE_APP_TWF_TOKEN, payload);
      commit('SET_TWF', payload)
    },
  },
  getters: {
    GET_USER: state => state.user,
    GET_PERMISSION: state => (permission) => state.user.permissions.find(p => p.permission === permission),
    GET_VERSION: state => state.version,
  },
});
