import Vue from "vue";
import Router from "vue-router";
import axios from 'axios'
import goTo from "vuetify/es5/services/goto";
import NProgress from "nprogress";
import store from './store/store';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: { name: 'Home' },
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresLogin: true
      },
      children: [
        {
          name: "Home",
          path: "home",
          component: () => import("@/views/Home"),
        },
        {
          name: "Catalog",
          path: "catalog",
          meta: {
            requiresPermission: true,
            permission: 'b2b'
          },
          component: () => import("@/views/Catalog"),
        },
        {
          name: "Perfil",
          path: "perfil",
          meta: {},
          component: () => import("@/views/perfil/Perfil"),
        },
      ],
    },

    {
      path: "/admin",
      redirect: { name: 'Accounts' },
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresLogin: true
      },
      children: [
        {
          name: "Accounts",
          path: "accounts",
          meta: {
            requiresPermission: true,
            permission: 'users'
          },
          component: () => import("@/views/admin/Accounts"),
        },
        {
          name: "Roles",
          path: "roles",
          meta: {
            requiresPermission: true,
            permission: 'users'
          },
          component: () => import("@/views/admin/Roles"),
        },
        {
          name: "Sectors",
          path: "sectors",
          meta: {
            requiresPermission: true,
            permission: 'users'
          },
          component: () => import("@/views/admin/Sectors"),
        },
      ]
    },

    {
      path: "/production",
      redirect: { name: 'Production' },
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresLogin: true
      },
      children: [
        {
          name: "ProductionFigures",
          path: "figures",
          meta: {
            requiresPermission: true,
            permission: 'statues'
          },
          component: () => import("@/views/production/ProductionFigures"),
        },
      ]
    },

    {
      path: "/statues",
      redirect: { name: 'Figures' },
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresLogin: true
      },
      children: [
        {
          name: "Figures",
          path: "figures",
          meta: {
            requiresPermission: true,
            permission: 'statues'
          },
          component: () => import("@/views/statues/Figures"),
        },
        {
          name: "Scales",
          path: "scales",
          meta: {
            requiresPermission: true,
            permission: 'statues'
          },
          component: () => import("@/views/statues/Scales"),
        },
        {
          name: "Franchises",
          path: "franchises",
          meta: {
            requiresPermission: true,
            permission: 'statues'
          },
          component: () => import("@/views/statues/Franchises"),
        }
      ],
    },

    {
      path: "/auth",
      redirect: { name: 'Signin' },
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Signin",
          path: "signin",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
      ],
    },

    {
      path: '*',
      redirect: '/'
    }
  ],
});


router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start(800);
  }
  next();
});

router.beforeEach((to, from, next) => {

  if (store.state.user) {
    axios.post(`${process.env.VUE_APP_BASE_URL}/validate_token`, { token: store.state.user.token })
      .then(() => store.dispatch('SET_USER', store.state.user))
      .catch(() => store.dispatch('LOGOUT'))
  }

  let user = store.getters.GET_USER

  to.matched.some(record => {
    if (record.meta.requiresLogin) user ? next() : next({ path: '/auth' })
    else if (record.meta.requiresPermission) {
      if (user.role.role) next()
      store.getters.GET_PERMISSION(record.meta.permission) ? next() : next({ path: '/' })
    }
    else {
      next()
    }
  })
})

router.afterEach(() => {
  NProgress.done();
});

export default router;
